
import React, { Fragment } from "react";
import { domain } from "../../../utils"

export const ParallaxImages = ({ columns }) => {
  return (
    <Fragment>
       <div className="col-12 col-lg-10 offset-lg-1 pt-4">
          <div className="pictures grid d-flex justify-content-center align-items-center">
            {columns.map((column, idx) => {
              return (
                <div key={idx} className="col-4 mt-lg-4 mt-2  px-lg-2 pr-0 text-center">
                  {column.img.map((img, index) => {
                    return img && img.url && (
                      <img key={`parallax_img_${index}`} className={'img-fluid img-section' + ((index === 0) ? ' mb-lg-5 mb-2': '')} src={`${domain}${img.url}`} alt=""/>
                    )
                  })}
                </div>
              );
            })}
          </div>
        </div>
    </Fragment>
  )
}

import React, { Fragment } from "react";
import { domain } from "../../../utils"
import Markdown from "markdown-to-jsx";

export const HomePageTeaser = ({ teaser, topics, language }) => {
  return (
    <Fragment>
      <div className="col-12 col-lg-10 offset-lg-1 pt-5 pb-3" id="exam-anxiety">
        <div className="row pt-4">
          <div className="col-lg-8 offset-lg-2 col-12 text-center">
            <Markdown>
              {teaser.title}
            </Markdown>
          </div>
        </div>
        <div className="row my-4">
          <div className="col-12 text-center">
            <a href={`/${teaser.button.slug}`} className="p-2 px-3 border-0 btn btn-danger">
              {teaser.button.label}
            </a>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-7 col-12 text-center py-3 py-md-0">
            {teaser && teaser.img && teaser.img.url && (
              <>
                <img className="w-100" src={`${domain}${teaser.img.url}`} alt=""/>
              </>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 offset-lg-3 col-12 text-center">
            <p>
              <Markdown>
                {teaser.description}
              </Markdown>
            </p>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 offset-lg-3 col-12 text-center">
            <a href={`/${teaser.button.slug}`} className="p-2 px-3 border-0 btn btn-danger">
              {teaser.button.label}
            </a>
          </div>
        </div>
      </div>

      <div className="col-12 col-lg-10 offset-lg-1 pt-5" id="courses">
        <div className="uk-grid">
          <div className="row">
            <div className="col-12">
              <h4>
                Mina will also deal with the following
                <br/>
                courses soon
              </h4>
            </div>
          </div>
        </div>

        <div className="uk-grid">
          <div className="row">
            {topics.map((topic, index) => {
              return (
                <div className="col-12 col-md-4 h-100">
                  <div className="list-item custom-list bg-white">
                    <ul className="px-2 mt-2 custom-list-red">
                      {topic.topic.map((item) => {
                        return (
                          <li className="pl-1">
                            {item.label}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Fragment>
  )
}
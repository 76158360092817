import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import { GlobalDispatchContext } from "../context/GlobalContextProvider"
import { BannerWithSubscription } from "../components/partials/banner/bannerWithSubscription"
import { ParallaxImages } from "../components/partials/parallax/parallaxImages"
import { HomePageTeaser } from "../components/partials/teaser/homepageTeaser"
import { domain } from "../utils"

import submit from "../images/newsletter-submit-icon.svg";
import Markdown from "markdown-to-jsx"
import '../functions.js';




const IndexPage = ({ data }) => {
  const {
    meta,
    language,
    header,
    footer,
    headerWithDescription,
    parallaxImageColumn,
    examAnxiety,
    topicsBox,
    features,
    benefits,
    subscription,
  } = data.strapi.homepage;
  
  const dispatch = useContext(GlobalDispatchContext)
  useEffect(() => {
    dispatch({ type: "SET_LANGUAGE", language })
  })

  console.log(data)
  return (
    <Layout header={header} footer={footer}>
      <SEO title={meta.title} description={meta.description} />
       <BannerWithSubscription banner={headerWithDescription} />
       {parallaxImageColumn && parallaxImageColumn.length > 0 && (
         <ParallaxImages columns={parallaxImageColumn}/>
       )}
      <HomePageTeaser teaser={examAnxiety} language={language} topics={topicsBox}/>

      {/* START FOURTH SECTION */}
        {features && (
          <div className="container-fluid list section-flow-top" id="features">
            <div className="row p-0">
              <div className="col-lg-10 offset-lg-1 col-12 pt-5">
                <div className="container-fluid pt-5">

                  {/* Mobile title */}
                  <div className="row d-block d-lg-none">
                    <div className="col-12 mobile-header mb-3">
                      <h2>
                        {features && features.title}
                      </h2>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-4 col-12 d-flex justify-content-lg-end align-item-center justify-content-center">
                      {/* <div className="carousel slide w-75" id="second-carousel" data-ride="carousel" data-interval="5000"> */}
                      <div className="carousel slide w-100" id="second-carousel" data-ride="carousel" data-interval="5000">
                        <div className="carousel-inner pl-md-5">
                          {features.slider && features.slider.length > 0 && features.slider.map((slide, index) => {
                            return slide.url && (
                              <div key={`mobile_slide_${index}`} className={'carousel-item' + ((index === 0) ? ' active' : '')}>
                                <img className="img-fluid w-100" src={`${domain}${slide.url}`}  alt=""/>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 offset-lg-1">

                      {/* Desktop title */}
                      <div className="row d-none d-lg-block ">
                        <div className="col-12 desktop-header">
                          <h2 className="text-left mt-2">
                            {features && features.title}
                          </h2>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 col-md-9 col-lg-11">
                          <ul className="list-unstyled">
                            {features.features.map((feature, index) => {
                              return (
                                <li key={`feature_${index}`} className="bg-white mt-3 p-2 list-item">
                                  {feature.description}
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-1 p-0 col-0">
              </div>
            </div>
          </div>
        )}
      {/* END FOURTH SECTION */}      



       {/* START FITH SECTION */}
        {/* {benefits && ( */}
          <div className="partial-benefits row" id="benefits">
            <div className="col-lg-10 col-12 offset-lg-1">
              <div className="container-fluid points">
                <div className="row">
                  <div className="col">
                    <h2>
                      {benefits.title}
                    </h2>
                  </div>
                </div>
                <div className="row mt-lg-5 mt-0">
                  {benefits.boxes && benefits.boxes.length > 0 && benefits.boxes.map((box, index) => {
                    return (
                      <div className="col-lg-6 col-12 d-flex align-items-center my-3 my-lg-4">
                        <div className="col-3">
                          <div className="points-circle d-flex">
                          </div>
                          <div className="points-circle-border position-absolute align-items-center justify-content-center d-flex">
                            <span className="points-circle-number">
                              {index + 1}.
                            </span>
                          </div>
                        </div>
                        <div className="col-9">
                          <div className="container-fluid">
                            <h3 className="m-0">
                              {box.title}
                            </h3>
                            <p className="mt-lg-3 mt-1 text-left">
                              <Markdown>
                                {box.description}
                              </Markdown>
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        {/* )} */}
        {/* END FIFTH SECTION */}



        {/* START NEWSLETTER SECTION */}
        {/* {pageContent && pageContent.subscription && ( */}
          <div className="container-fluid newsletter" id="newsletter">
            <div className="row mx-lg-1 pr-0 newsletter-background">
              <div className="col-lg-1 col-0 p-0">
              </div>
              <div className="col-lg-10 col-12">
                <div className="container-fluid newsletter-container">
                  <div className="row justify-content-center">
                    <div className="col-lg-10 col-12">
                      <h2 className="text-white">
                        {subscription.title}
                      </h2>
                      <p className="text-white px-lg-5 px-0">
                        {subscription.description}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-8 offset-lg-2 col-12">
                      <div className="row justify-content-center">
                        <div className="col-12 col-lg-8">
                          <div className="emailoctopus-form-wrapper emailoctopus-form-default null w-100">
                            <p className="emailoctopus-success-message lg-m-1 text-white">
                            </p>
                            <p className="emailoctopus-error-message lg-m-1 text-white">
                            </p>
                            <form 
                              className="emailoctopus-form form-horizontal bound" 
                              action="https://emailoctopus.com/lists/b6e22eed-3f84-11eb-a3d0-06b4694bee2a/members/embedded/1.3s/add" 
                              method="post" 
                              data-message-success="Thanks for subscribing!" 
                              data-message-missing-email-address="Your email address is required." 
                              data-message-invalid-email-address="Your email address looks incorrect, please try again." 
                              data-message-bot-submission-error="This doesn't look like a human submission." 
                              data-message-consent-required="Please check the checkbox to indicate your consent." 
                              data-message-invalid-parameters-error="This form has missing or invalid fields." 
                              data-message-unknown-error="Sorry, an unknown error has occurred. Please try again later." 
                              data-sitekey="6LdYsmsUAAAAAPXVTt-ovRsPIJ_IVhvYBBhGvRV6" 
                              data-widget-id="1"
                            >
                              <div className="emailoctopus-form-row form-group d-flex align-items-center justify-content-center mb-0 mt-3">
                                <input 
                                  className="header-input input-lg bg-light border-0 form-control px-2 px-lg-3" 
                                  id="field_0" 
                                  name="field_0" 
                                  type="email" 
                                  placeholder="Type in your email address" 
                                  required={true}
                                />
                                <div className="emailoctopus-form-row-subscribe">
                                  <input type="hidden" name="successRedirectUrl"/>
                                  <button className="bg-transparent mb-0 ml-1 p-0 border-0" type="submit">
                                    <img className="p-2 rounded-circle border-0 bg-danger submit-button" src={submit} alt="subscribe"/>
                                  </button>
                                </div>
                              </div>
                              <div className="g-recaptcha d-none" data-theme="light" data-size="invisible" data-type="image" data-sitekey="6LdYsmsUAAAAAPXVTt-ovRsPIJ_IVhvYBBhGvRV6">
                                <div className="grecaptcha-badge" data-style="none">
                                  <div className="grecaptcha-logo">
                                    <iframe 
                                      title="grecaptcha-logo"
                                      src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6LdYsmsUAAAAAPXVTt-ovRsPIJ_IVhvYBBhGvRV6&amp;co=aHR0cHM6Ly93d3cubWluYWJvdC5haTo0NDM.&amp;hl=de&amp;type=image&amp;v=-nejAZ5my6jV0Fbx9re8ChMK&amp;theme=light&amp;size=invisible&amp;cb=r4s9el81lfz6" 
                                      width="256" 
                                      height="60" 
                                      role="presentation" 
                                      name="a-vs0smw9uah8" 
                                      scrolling="no" 
                                      sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"
                                    />
                                  </div>
                                  <div className="grecaptcha-error">
                                  </div>
                                  <textarea 
                                    id="g-recaptcha-response-1" 
                                    name="g-recaptcha-response" 
                                    className="g-recaptcha-response" 
                                  />
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-center newsletter-subscription">
                        <div className="col-12 col-lg-8 d-flex justify-content-center mt-1">
                          <div className="input-spacer px-2 px-lg-3">
                            <div className="privacy-note mr-lg-5 text-white">
                              {language === 'en' ? (
                                <span> Please note our <a className="text-white underline" href="/en/privacy-policy" id="newsletter-link">privacy policy.</a></span>
                              ) : language === 'de' && (
                                <span> Bitte beachte unsere <a className="text-white underline" href="/de/datenschutz" id="newsletter-link">Datenschutzbestimmungen.</a></span>
                              )}
                            </div>
                          </div>
                          <div className="button-spacer d-block ml-1 p-0">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-1 col-0 p-0">
              </div>
            </div>
          </div>
        {/* )} */}
        {/* END NEWSLETTER SECTION */}
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query Homepage($id: ID!) {
    strapi {
      homepage(id: $id) {
        meta {
          title
          description
        }
        language
        header {
          logo {
            url
          }
          links {
            label
            slug
          }
        }
        footer {
          copyright
          navItems {
            label
            slug
          }
        }
        headerWithDescription {
          title
          description
        }
        parallaxImageColumn {
          img {
            name
            url
          }
        }
        examAnxiety {
          description
          img {
            url
          }
          title
          button {
            slug
            label
          }
        }
        topicsBox {
          topic {
            label
          }
        }
        features {
          slider {
            url
          }
          title
          features {
            description
          }
        }
        benefits {
          title
          boxes {
            title
            description
          }
        }
        subscription {
          title
          description
        }
      }
    }
  }
`

import React, { Fragment, useContext } from "react";
import { GlobalStateContext } from "../../../context/GlobalContextProvider";
import Markdown from "markdown-to-jsx";
import submit from "../../../images/newsletter-submit-icon.svg";


export const BannerWithSubscription = ({ banner }) => {
  const state = useContext(GlobalStateContext);
  const { language } = state;

  return (
    <Fragment>
      <div className="col-12 col-lg-10 offset-lg-1">
        <div className="header container-fluid mt-lg-4 mt-5 pt-lg-5" id="home">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-12">
              <h1 className="text-center">
                {banner && banner.title}
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-12 p-2 p-xxl-3">
              <p className="mx-lg-3 text-center">
                {banner && banner.description && (
                  <Markdown>
                    {banner.description}
                  </Markdown>
                )}
              </p>
              <div>
                <div className="row justify-content-center">  
                  <div className="col-12 col-lg-8">
                    <div className="emailoctopus-form-wrapper emailoctopus-form-default null w-100">
                      <form 
                        className="emailoctopus-form form-horizontal bound" 
                        action="https://emailoctopus.com/lists/b6e22eed-3f84-11eb-a3d0-06b4694bee2a/members/embedded/1.3s/add" 
                        method="post" 
                        data-message-success="Thanks for subscribing!" 
                        data-message-missing-email-address="Your email address is required." 
                        data-message-invalid-email-address="Your email address looks incorrect, please try again." 
                        data-message-bot-submission-error="This doesn't look like a human submission." 
                        data-message-consent-required="Please check the checkbox to indicate your consent." 
                        data-message-invalid-parameters-error="This form has missing or invalid fields." 
                        data-message-unknown-error="Sorry, an unknown error has occurred. Please try again later." 
                        data-sitekey="6LdYsmsUAAAAAPXVTt-ovRsPIJ_IVhvYBBhGvRV6" 
                        data-widget-id="0"
                      >
                        <div className="emailoctopus-form-row form-group d-flex align-items-center justify-content-center mb-0 mt-2">
                          <input 
                            className="header-input input-lg bg-light border-0 form-control px-2 px-lg-3" 
                            id="field_0" name="field_0" type="email" 
                            placeholder="Type in your email address" 
                            required={true}
                          />
                          <div className="emailoctopus-form-row-subscribe">
                            <input type="hidden" name="successRedirectUrl"/>
                            <button className="bg-transparent mb-0 ml-1 p-0 border-0" type="submit">
                              <img className="p-2 rounded-circle border-0 bg-danger submit-button" src={submit} alt=""/>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="newsletter-subscription-wrapper">
                <div className="row justify-content-center newsletter-subscription"> 
                  <div className="col-12 col-lg-8 d-flex justify-content-center">
                    <div className="input-spacer px-2 px-lg-0 mt-1">
                      <div className="privacy-note mr-lg-5 pr-lg-3">
                        {language === 'en' ? (
                          <span> Please note our <a className="text-info" href="/en/privacy-policy" id="newsletter-link">privacy policy.</a></span>
                        ) : language === 'de' && (
                          <span> Bitte beachte unsere <a className="text-info" href="/de/datenschutz" id="newsletter-link">Datenschutzbestimmungen.</a></span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}